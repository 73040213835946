<script>
import InputField from '@/components/general/InputField'
import UserListItem from '@/components/general/UserListItem'
import EmptyList from '@/components/general/EmptyList'
import Loading from '@/components/general/Loading.vue'
export default {
  components: { InputField, UserListItem, EmptyList, Loading },
  name: 'RemovedUsers',
  data () {
    return {
      search: '',
      isReady: false,
      users: [],
      data: []
    }
  },
  created () {
    this.getRemovedUsers()
  },
  methods: {
    getRemovedUsers () {
      this.$store.dispatch('attemptGetUsers')
        .then(({ data }) => {
          this.isReady = true
          this.users = data.users.filter(u => !u.active).map((user) => (
            {
              id: user.id,
              name: user.fullName,
              lastName: user.fullName.split(' ').slice(1).join(' '),
              email: user.email,
              active: user.active,
              avatarImage: user.avatar.indexOf('/empty') === -1 ? user.avatar : '/assets/images/profile-avatar.jpg',
              role: user.role
            }
          ))
          this.users.sort((a, b) => a.name.localeCompare(b.name))
          this.data = this.users
        })
    }
  },
  watch: {
    search: function (value) {
      this.users = this.data.filter(user => {
        return user.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
    }
  }
}
</script>
<template>
  <div>
    <Loading v-if="!isReady"/>
    <div class="removed-users--container" v-else>
      <div class="removed-users--filter-actions">
        <input-field class="mr-2" outlined dense :label="$t('global:search.alt')" v-model="search" append-icon="mdi-magnify"></input-field>
      </div>
      <div class="removed-users--list">
        <empty-list
          v-if="users.length == 0"
          :title="$t('empty.state.title:active.user.removed.title')"
          :textOne="$t('empty.state.text:active.user.removed.text')"
          :image="'/assets/images/empty-bush.png'"
        ></empty-list>
        <user-list-item v-for="user in users" :key="user.id" :user="user" @userReactivated="getRemovedUsers()"></user-list-item>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.removed-users--container {
  .removed-users--filter-actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
